/* General */
.contenido {
    margin-top: 60px; /* Separación con el navbar */
}

#video-section {
    position: relative;
    width: 100%;
    padding-top: 56.25%; /* 16:9 Aspect Ratio */
}

#video-section iframe {
    position: absolute;
    top: 0;
    left: 0;
    margin-left:5vw;
    width: 100%;
    height: 100%;
}

/* Sección de proyectos */
.proyectos {
    text-align: center;
}

.proyectos h2
{
    text-align: left;
    margin-bottom: 1vw;
    margin-left: 5vw;
    font-size: 1.8vw; /* Un tamaño ligeramente más pequeño que 2.4vw */
    font-family: 'Aptos', sans-serif;
    letter-spacing: 1.5vw; /* Reducir el espaciado entre letras */
    color: #716e6e;
}


.comercial h2
{
    text-align: left;
    margin-bottom: 1vw;
    margin-left: 5vw;
    font-size: 1.8vw; /* Un tamaño ligeramente más pequeño que 2.4vw */
    font-family: 'Aptos', sans-serif;
    letter-spacing: 1.5vw; /* Reducir el espaciado entre letras */
    color: #716e6e;
}


.contacto h2{
    text-align: left;
    margin-bottom: 0vw;
    margin-left: 5vw;
    font-size: 1.8vw; /* Un tamaño ligeramente más pequeño que 2.4vw */
    font-family: 'Aptos', sans-serif;
    letter-spacing: .6vw; /* Reducir el espaciado entre letras */
    color: #716e6e;
}


/* Sección "Nosotros" */
.nosotros {
    text-align: left;
    margin-bottom: 1vw;
    margin-left: 5vw;
    font-size: 1.8vw; /* Un tamaño ligeramente más pequeño que 2.4vw */
    font-family: 'Aptos', sans-serif;
    letter-spacing: 1.5vw; /* Reducir el espaciado entre letras */
    color: #716e6e;
}



/* Sección de contacto */
.contacto {
    text-align: left;
    margin: 10vw 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 5vw;
}

.formulario-contacto {
    width: 90%;
    max-width: 600px;
    background-color: #f9f9f9;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    margin-bottom: 5vw;
}

.formulario-contacto label {
    display: block;
    margin-bottom: 10px;
    font-weight: bold;
}

.formulario-contacto input,
.formulario-contacto textarea {
    width: 100%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 1rem;
}

/* Botón de envío */
.boton-enviar {
    background-color: #757575;
    color: white;
    border: none;
    padding: 10px 20px;
    font-size: 1.2rem;
    border-radius: 5px;
    cursor: pointer;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s ease, transform 0.2s ease;
}

.boton-enviar:hover {
    background-color: #5a5a5a;
    transform: translateY(-2px);
}

/* Sección de video */
#video-section {
    width: 89%;
    margin: 0 auto 10vw;
}

.contenido-video {
    width: 100%;
    height: auto;
}

/* Grid para imágenes residenciales */
.residenciales-grid {
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 5vw;
    gap: 5vw;
    padding: 10px;
}

.grid-general {
    display: flex;
    flex-wrap: wrap; /* Permitir múltiples filas si es necesario */
    justify-content: center; /* Centrar las imágenes dentro del grid */
    gap: 3vw; /* Espaciado entre imágenes */
    margin: 5vw auto; /* Centrar y agregar espacio vertical */
    width: 90%; /* Coincidir con el video */
}

.proyecto-imagen {
    width: 100%; /* Igualar el ancho al video */
    max-width: 89vw; /* Coincide con el ancho del video contenedor */
    height: auto; /* Mantener la proporción */
    border-radius: 3px;
    box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
}

.imagen-titulo {
    font-size: 1.5vw;
    color: #716e6e;
    text-align: left;
    margin: 5px 0 3vw;
    font-family: 'Aptos', sans-serif;
    margin-bottom: 3vw;
}

.proyecto-item {
    display: flex;
    flex-direction: column;
    margin: 10px auto; /* Centrar en el grid */
    width: 100%; /* Mantener el ancho consistente */
}

.texto p {
    text-align: justify;
    margin-top: 10vw;
    font-family: 'Aptos', sans-serif;
    font-size: 1.5vw;
}

.texto {
    text-align: justify; /* Justifica el texto */
    margin-top: 3vw; /* Ajusta la distancia respecto al título */
    font-family: 'Aptos', sans-serif;
    font-size: 1.5vw; /* Mantén una proporción adecuada */
    width: 90%; /* Coincide con el ancho de las imágenes */
    margin-left: auto; /* Centra el contenido horizontalmente */
    margin-right: auto;
    margin-bottom: 5vw;
    line-height: 1.8; /* Mejora el espaciado entre líneas */
}

.texto_sa {
    text-align: justify; /* Justifica el texto */
    margin-top: 3vw; /* Ajusta la distancia respecto al título */
    font-family: 'Aptos', sans-serif;
    font-size: 1.5vw; /* Mantén una proporción adecuada */
    width: 90%; /* Coincide con el ancho de las imágenes */
    margin-left: auto; /* Centra el contenido horizontalmente */
    margin-right: auto;
    margin-bottom: 5vw;
    line-height: 1.8; /* Mejora el espaciado entre líneas */
}


@media screen and (max-width: 768px) {
    .proyecto-imagen {
        width: 80vw; /* Abarca todo el ancho de la pantalla */
        max-width: none; /* Elimina restricciones de ancho máximo */
        height: auto; /* Mantiene la proporción */
    }

    .proyectos h2,
    .contacto h2,
    .nosotros h2 {
        font-size: 4vw; /* Escalar títulos para dispositivos medianos */
        margin-left: 5vw;
    }

    .grid-general {
        gap: 5vw; /* Espaciado entre elementos del grid */
        padding: 5px;
    }
}

@media screen and (max-width: 480px) {
    .proyecto-imagen {
        width: 80vw; /* Abarca todo el ancho de la pantalla */
        max-width: none; /* Asegura que no haya restricciones */
        height: auto; /* Mantiene proporciones */
    }

    .proyectos h2,
    .contacto h2,
    .nosotros h2 {
        font-size: 5vw; /* Aumenta el tamaño de los títulos para pantallas pequeñas */
        margin-left: 5vw;
    }

    .grid-general {
        gap: 2vw; /* Reduce ligeramente el espacio entre elementos en pantallas más pequeñas */
        padding: 0px;
    }
}
