/* Cambiar el tamaño, fuente y separación de los ítems */
.navbar-nav .nav-link {
    font-size: 20px; /* Tamaño de fuente */
    font-family: 'Aptos', sans-serif; /* Fuente */
    margin-right: 20px; /* Separación entre ítems */
    color: #333; /* Color del texto */
    transition: color 0.3s ease; /* Efecto suave al pasar el mouse */
}

.navbar-nav .nav-link:hover {
    color: #DA8718; /* Color al pasar el mouse */
}

/* Dropdown personalizado */
#nav-dropdown {
    font-size: 20px; /* Tamaño de fuente */
    font-family: 'Aptos', sans-serif; /* Fuente */
    color: #333; /* Color del texto */
    background-color: white;
}

#nav-dropdown .dropdown-item {
    font-size: 20px; /* Tamaño de las opciones en el dropdown */
    color: #333; /* Color del texto */
    background-color: white;
}

#nav-dropdown .dropdown-item:hover {
    background-color: #f8f9fa; /* Fondo al pasar el mouse */
    color: #007bff; /* Color del texto al pasar el mouse */
}

/* Estilizar el logo */
.navbar-brand img {
    height: 80px; /* Ajustar la altura del logo */
    width: auto; /* Mantener proporción */
}

/* Espaciado del navbar */
.navbar {
    padding: 10px 20px; /* Ajustar el relleno superior e inferior */
}

.navbar .social-icons .nav-link {
    color: #333; /* Color predeterminado de los íconos */
    margin-left: 15px; /* Espaciado entre íconos */
    transition: color 0.3s ease;
}

.navbar .social-icons .nav-link:hover {
    color: #007bff; /* Color al pasar el mouse */
}

.navbar .social-icons .nav-link .fa-icon {
    font-size: 20px; /* Tamaño de los íconos */
}

/* Ajuste del navbar para pantallas pequeñas */
@media screen and (max-width: 768px) {
    .navbar {
        height: 100px; /* Reducir altura del navbar */
        padding: 0 10px; /* Reducir padding lateral */
        font-size: 0.9rem; /* Reducir tamaño de fuente en el navbar */
        background-color: white !important; /* Fondo blanco */
    }

    .navbar-collapse {
        background-color: white; /* Fondo blanco para el menú colapsado */
    }

    .navbar-logo {
        height: 30px; /* Reducir altura del logo */
        width: auto; /* Mantener la proporción del logo */
    }

    .navbar a {
        font-size: 0.85rem; /* Reducir tamaño de los enlaces */
        margin: 0 5px; /* Reducir espacio entre los enlaces */
    }
}

/* Ajuste adicional para móviles muy pequeños */
@media screen and (max-width: 480px) {
    .navbar {
        height: 100px; /* Reducir más la altura */
        font-size: 0.8rem; /* Reducir tamaño de fuente adicional */
        background-color: white !important; /* Fondo blanco */
    }

    .navbar-collapse {
        background-color: white; /* Fondo blanco para el menú colapsado */
    }

    .navbar-logo {
        height: 25px; /* Reducir más el tamaño del logo */
    }

    .navbar a {
        font-size: 0.75rem; /* Reducir tamaño de los enlaces */
    }
}